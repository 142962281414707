import message from '../../i18n/es-mx';
import { vwoCodeMX } from '../const';

// Публичный ключ от рекапчи. Подставляется в Head Scripts
const grecaptchaPublicKey = '6LfR9OopAAAAAPsHO9XuBuXfIKZR4kukqm9mAMoh';

const API = {
  REST_API: {
    CMS_REST_API: 'https://cms.ebac.mx/api',
    BILLING_REST_API: 'https://billing.ebac.app',
    LMS_REST_API: 'https://api.lms.ebac.mx',
  },
  ENDPOINTS: {
    METRICS_URL: 'https://metrics.ebac.app/metrics',
    CUSTOMER_IO_URL:
      'https://fkwefind93.execute-api.sa-east-1.amazonaws.com/default/billing_customer-io_get-customer-attributes',
    // Lambda, которая возвращает cloudfront info о пользователе
    cloudFrontInfoUrl: 'https://widgets.ebac.app/cloudfront-viewer-info',
    getPaymentLink: 'https://ebac.mx/api/subscriptions/getPaymentLink',
    getDetailWithPaymentLink:
      'https://billing.ebac.app/offer/detail-with-links',
    webinarVisitorEventsUrl:
      'https://wisggajuuczifmvdtlzwluwpei0urtta.lambda-url.sa-east-1.on.aws/webinar-visitors-webhook',
    webinarsCreateLeadUrlAws:
      'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/mx/subscribe/webinar',
    showcaseApiUrl: 'https://cms.ebac.mx/api/public/v2/showcase',
    checkSubscriptionsLink: 'https://api.lms.ebac.mx/subscriptions/is-mine',
    leadFormUrl:
      'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/queue/mx/subscribe/course',
    saleUrl:
      'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/queue/mx/subscribe/course',
    promocodeUrl: 'https://cms.ebac.mx/api/shopping-cart',
    wordPressPosts: 'https://ebac.mx/blog/wp-json/wp/v2/posts',
    feedbackUrl:
      'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/mx/subscribe/register',
  },
};

const SECURITY = {
  GRECAPTCHA: {
    grecaptchaPublicKey,
    grecaptchaEnabled: true,
  },
};

const ANALYTICS = {
  gtm: {
    debug: false,
    id: 'GTM-TQR2Q6B',
    autoInit: true,
    enabled: true,
    pageTracking: true,
    scriptDefer: true,
    respectDoNotTrack: false,
  },
};

const LOCALIZATION = {
  I18N: {
    localeName: 'es-mx',
    utcOffset: -6,
    message,
    numberFormat: {
      currency: {
        style: 'currency',
        currency: 'MXN',
        currencyDisplay: 'symbol',
      },
      currencyNoCents: {
        style: 'currency',
        currency: 'MXN',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
      },
    },
    defaultPhoneMask: 'mx',
  },
  SEO: {
    hreflang: 'es-MX',
    href: 'https://ebac.mx',
    code: 'mx',
  },
};

const MARKETING = {
  saleBanner: {
    endDate: '2023-06-12T05:59:00Z', // пример 2022-08-27T05:59:00Z - до 26 августа включительно
    eternal: true, // вечная распродажа? если да, то endDate можно не заполнять (если ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, ПН, то до ПН)
    eternalV2: false, // вечная распродажа? если да, то endDate можно не заполнять (если ПН, ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, то до ВС)
    name: '020823', // название распродажи, формат ddmmyy
    link: 'https://ebac.mx/sale/promociones', // ссылка на распродажу
  },
  sourceId: {
    index: 10003,
    sale: 11000,
    saleBasket: 11000,
  },
};

const COMMUNICATION = {
  SOCIAL: {
    phone: '+52 55 9225-2629',
    phoneLink: 'tel:+525592252629',
    whatsApp: '+52 1 55 9225-2629',
    whatsAppLink: 'https://wa.me/5215592252629',
    email: '',
    emailLink: '',
    instagramLink: 'https://www.instagram.com/ebac_latam',
    facebookLink: 'https://www.facebook.com/EbacLATAM',
    youtubeLink: 'https://www.youtube.com/channel/UCVqYXk6kbrdcDbmPtOw9v4g',
    linkedInLink: 'https://www.linkedin.com/company/ebaclatam',
    tiktok: 'https://www.tiktok.com/@ebac_latam',
  },
  WEBINARS: {
    withWebinars: true,
    webinarsUrl: '/webinars',
  },
  BLOG: {
    withBlog: true,
    tildaBlog: false,
    blogUrl: 'https://ebac.mx/blog',
    getBlogsUrl: 'https://ebac.mx/api/tilda-main-blog',
    blogLinks: [
      {
        href: 'https://ebac.mx/blog#!/tab/332850082-2',
        key: 'template.design',
      },
      {
        href: 'https://ebac.mx/blog#!/tab/332850082-3',
        key: 'template.gaming',
      },
    ],
  },
  B2B: {
    b2bLinks: {
      corporateEducation: {
        url: 'https://ebac.mx/para-empresas/educacion-corporativa',
      },
      challenges: { url: 'https://ebac.mx/para-empresas/atraccion-de-talento' },
    },
  },
  LINKS: {
    externalVacanciesLink: 'https://ebac_latam.gupy.io/',
    // URL главной страницы
    lmsUrl: 'https://lms.ebac.mx/',
    // URL страницы login
    lmsLoginUrl: 'https://lms.ebac.mx/login',
  },
};

const LEGAL = {
  DOCUMENTS: {
    documents: {
      privacyNoticeStudents:
        'https://ebac.mx/upload/cms/ebac_aviso_de_privacidad_integral_alumnos.pdf',
      privacyNoticeTeachers:
        'https://ebac.mx/upload/cms/ebac_aviso_de_privacidad_integral_profesores.pdf',
      termsAndConditions:
        'https://ebac.mx/upload/cms/ebac_tyc_alumnos_pag_cursos.pdf',
      cursos: 'https://ebaconline.com.br/cursos',
    },
    footerDocuments: [
      'privacyNoticeStudents',
      'privacyNoticeTeachers',
      'termsAndConditions',
      'cursos',
    ],
    formDocument: 'privacyNoticeStudents',
  },
  PAGE_404: {
    additionalText404: false,
    links404: [
      {
        key: 'courses',
        link: '/cursos?types=course',
      },
      {
        key: 'professions',
        link: '/cursos?types=profession',
      },
      {
        key: 'jobGuarantee',
        link: '/cursos?jobGuarantee=true',
      },
      {
        key: 'upcomingEvents',
        link: '/webinars',
      },
      {
        key: 'blogArticles',
        link: 'https://ebac.mx/blog',
      },
      {
        key: 'aboutUs',
        link: '/about-us',
      },
    ],
  },
};

const HEAD = {
  // Global head
  head: {
    lang: 'es',
    meta: [
      {
        name: 'facebook-domain-verification',
        content: 'xxovr0wpum73urzqw7s1xn4rzw3gi2',
      },
      {
        name: 'google-site-verification',
        content: 'yzzTXNX8OXD7QrGXUwIRewJQdvFo53rDEAiKmqGOa_4',
      },
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/png',
        href: 'https://ebac.mx/favicons/favicon-48x48.png',
        sizes: '48x48',
      },
      {
        rel: 'icon',
        type: 'image/svg+xml',
        href: 'https://ebac.mx/favicons/favicon.svg',
      },
      {
        rel: 'shortcut icon',
        href: 'https://ebac.mx/favicons/favicon.ico',
      },
      {
        rel: 'apple-touch-icon',
        href: 'https://ebac.mx/favicons/apple-touch-icon.png',
        sizes: '180x180',
      },
      {
        rel: 'manifest',
        // crossOrigin: 'use-credentials',
        href: 'https://ebac.mx/favicons/site.webmanifest',
      },

      // Преконнект сервиса a/b тестов
      {
        rel: 'preconnect',
        href: 'https://dev.visualwebsiteoptimizer.com',
      },
    ],
    script: [
      // {
      //   innerHTML: `(function(a,m,o,c,r,m){a[m]={id:"55739",hash:"1ff7300d2677990955830635bd87352e4d8646284065bebd0d2b4738baf4a888",locale:"en",inline:true,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.com/js/button.js?1630666958';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));`,
      //   type: 'text/javascript',
      //   async: true,
      // },

      {
        src: 'https://cdn.jsdelivr.net/npm/vue@2.6.14',
        body: true,
        defer: true,
      },
      {
        src: 'https://widgets.ebac.app/cookie-consent/mx/cookie-consent-init.js',
        body: true,
        defer: true,
      },
      {
        src: 'https://widgets.ebac.app/cookie-consent/mx/cookie-consent.min.js',
        body: true,
        defer: true,
      },

      // Подключение сервиса a/b тестов
      {
        type: 'text/javascript',
        id: 'vwoCode',
        innerHTML: vwoCodeMX,
      },

      // Подключение Google Recaptcha v3
      {
        src: `https://www.google.com/recaptcha/api.js?render=${grecaptchaPublicKey}`,
        body: true,
        defer: true,
      },
    ],
    __dangerouslyDisableSanitizers: ['script'],
  },
  bodyScripts: [],
  // head скрипты страницы "центр карьеры студентов"
  careerCenterStudentsScripts: [],
};

export default {
  ...API.REST_API,
  ...API.ENDPOINTS,

  ...SECURITY.GRECAPTCHA,

  ...ANALYTICS,

  ...LOCALIZATION.I18N,
  ...LOCALIZATION.SEO,

  saleBanner: MARKETING.saleBanner,
  sourceId: MARKETING.sourceId,

  ...COMMUNICATION.SOCIAL,
  ...COMMUNICATION.WEBINARS,
  ...COMMUNICATION.BLOG,
  ...COMMUNICATION.B2B,
  ...COMMUNICATION.LINKS,

  ...LEGAL.DOCUMENTS,
  ...LEGAL.PAGE_404,

  ...HEAD,

  // Домен страны
  domain: 'ebac.mx',
  // Домен, который заменяет домен у некоторых ссылок
  // приходящих из cms
  newDomainName: '',
  // Наличие гарантии трудоустройства
  jobGuarantee: true,
  // Отображать ли checkbox принятия политики конфиденциальности
  // у форм
  formWithCheckbox: true,
};
