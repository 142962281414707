import Vue from 'vue';
import cookie from 'js-cookie';
import config from '@/config/countries';
import proxyStore from '../utils/proxyStore';
import { sentryReplaysForUsers } from '@/utils/sentryReplaysForUsers';
import { setSentryGRecaptchaContextAndTag } from '@/utils/grecaptcha';

const sbjs = require('sourcebuster');

const ROUTES_WITH_AUTH_SSR = ['/sale'];

export default async ({ app, /*error, */ $config, req, route }, inject) => {
  let user = null;
  const conf = config($config.COUNTRY);

  const cookies = process.browser ? document.cookie : req.headers.cookie;
  const isAuthSSR = new RegExp(`^${ROUTES_WITH_AUTH_SSR.join('|')}`).test(
    route.path,
  );

  if ((process.client || isAuthSSR) && cookies) {
    const aToken = await app.store.dispatch('user/getAToken', { cookies });
    const LMS_REST_API = conf.current.LMS_REST_API;
    if (aToken) {
      try {
        const response = await app.$axios({
          method: 'get',
          url: `${LMS_REST_API}/auth/info`,
          headers: {
            Authorization: `Bearer ${aToken}`,
          },
        });

        user = response?.data;
      } catch (error) {
        const code = parseInt(error.response && error.response.status);

        if (code && code === 401) {
          if (process.client) {
            app.store.dispatch('user/logout');
            await app.store.dispatch('user/logoutFromLms');
          }
        } else {
          console.error('Error in app plugin (auth)', error);
        }
      }
    }
  }

  if (process.server) {
    await app.store.dispatch('directions/getDirections');
    await proxyStore.dispatch({ type: 'getStore', store: app.store });
  }

  if (process.client) {
    await sentryReplaysForUsers(user, route.query.sreplay === 'true');
  }

  const directions = app.store.state.directions.list;
  const webinarsDirections = [
    {
      active: true,
      id: null,
      eventMetaDescription: app.i18n.t(
        'webinar.directions.all.eventMetaDescription',
      ),
      eventMetaKeywords: null,
      eventMetaTitle: app.i18n.t('webinar.directions.all.eventMetaTitle'),
      eventSeoText: null,
      eventSlug: null,
      eventTitle: app.i18n.t('webinar.directions.all.eventTitle'),
    },
    ...directions,
  ];
  app.store.commit(
    'webinars/setDirections',
    Object.assign([], webinarsDirections),
  );

  app.store.commit(
    'eventsList/SET_DIRECTIONS',
    Object.assign([], webinarsDirections),
  );

  app.store.commit(
    'courses/setDirections',
    Object.assign(
      [],
      [
        {
          id: null,
          metaKeywords: '',
          metaDescription: app.i18n.t('direction.0.metaDescription'),
          metaTitle: app.i18n.t('direction.0.metaTitle'),
          seoText: app.i18n.t('direction.0.seoText'),
          title: app.i18n.t('direction.0.title'),
          slug: 'cursos',
        },
        ...directions,
      ],
    ),
  );
  app.store.commit(
    'blog/setDirections',
    Object.assign(
      [],
      [
        {
          id: null,
          metaKeywords: '',
          metaDescription: app.i18n.t('blog.directions.all.metaDescription'),
          metaTitle: app.i18n.t('blog.directions.all.metaTitle'),
          seoText: app.i18n.t('blog.directions.all.seoText'),
          title: app.i18n.t('blog.directions.all.title'),
          slug: 'all',
          shortName: 'All',
        },
        ...directions,
      ],
    ),
  );

  // При загрузке страницы получаем recaptcha_score из localStorage
  // И добавляем его в Sentry context и tag
  // При отправке любой формы context и tag обновляются
  if (process.client) {
    setSentryGRecaptchaContextAndTag(conf);
  }

  inject(
    'app',
    new Vue({
      i18n: app.i18n,
      data() {
        return {
          isMobile: false,
          breakPoint: 1280,
          backToTopButtonBottomOffset: 0,

          user,
          config: conf,
          cookies: {
            lastScheduleEvent: null,
            lastBlogArticleVisited: null,
          },

          ui: {
            webinars: {
              isDisplayPromoBlockRegistration: false,
            },
          },
        };
      },
      computed: {
        isEmbeddedBlocksHidden() {
          return app.store.state.ui.isEmbeddedBlocksHidden;
        },
      },
      watch: {
        'ui.webinars.isDisplayPromoBlockRegistration'() {
          if (process.client) {
            this.debug(
              { name: 'app watch ui.webinars.isDisplayPromoBlockRegistration' },
              'recalculateCookieConsent',
            );
            window.dispatchEvent(new Event('recalculateCookieConsent'));
          }
        },
        isEmbeddedBlocksHidden: {
          handler(payload) {
            if (window) {
              const body = document.body;
              if (payload) {
                body.classList.add('embeddedBlocksHidden');
              } else {
                body.classList.remove('embeddedBlocksHidden');
              }
            }
          },
        },
      },
      created() {
        if (process.browser) {
          this.sbjs = sbjs;
          if (typeof sbjs === 'object' && typeof sbjs.init === 'function') {
            sbjs.init();
          } else {
            console.info(`no sbjs`);
          }

          if (conf.current?.bodyScripts.length) {
            for (let script of conf.current.bodyScripts) {
              this.addScriptToBody(script);
            }
          }
        }

        setTimeout(() => {
          this.cookies.lastScheduleEvent = this.getCookie(
            'last_schedule_event',
          );
          this.cookies.lastBlogArticleVisited = this.getCookie(
            'last_blog_article_visited',
          );
        }, 0);
      },
      methods: {
        getCurrency(currency) {
          const currencyNumber = Number(currency);
          if ((currencyNumber ^ 0) === currencyNumber) {
            return this.$n(currencyNumber, 'currencyNoCents');
          } else {
            return this.$n(currencyNumber, 'currency');
          }
        },
        getCookie(cookieName) {
          return cookie.get(cookieName);
        },
        addScriptToBody(script) {
          const scriptNode = document.createElement('script');
          scriptNode.src = script.src;

          if (Object.prototype.hasOwnProperty.call(script, 'type')) {
            scriptNode.type = script.type;
          }
          if (Object.prototype.hasOwnProperty.call(script, 'async')) {
            scriptNode.async = script.async;
          }
          if (Object.prototype.hasOwnProperty.call(script, 'defer')) {
            scriptNode.defer = script.defer;
          }
          document.body.appendChild(scriptNode);
        },
      },
    }),
  );
};
