import Router from 'vue-router';
import proxyStore from './utils/proxyStore';
import OldIndex from './pages/index1.vue';

export async function createRouter(
  ssrContext,
  createDefaultRouter,
  routerOptions,
  config,
) {
  const options = routerOptions
    ? routerOptions
    : createDefaultRouter(ssrContext, config).options;

  let LOCALE;
  switch (config.COUNTRY) {
    case 'mx':
    case 'co':
      LOCALE = 'es-mx';
      break;

    default:
      LOCALE = 'pt-br';
      break;
  }

  return new Router({
    ...options,
    routes: fixRoutes(options.routes, LOCALE, config.COUNTRY),
    scrollBehavior(to, from /*, savedPosition*/) {
      if (
        (to.name === `courses___${LOCALE}` &&
          from.name === `coursesTopic___${LOCALE}`) ||
        (to.name === `coursesTopic___${LOCALE}` &&
          from.name === `coursesTopic___${LOCALE}`) ||
        (to.name === `webinars-directions___${LOCALE}` &&
          from.name === `webinars-directions___${LOCALE}`) ||
        (to.name === `sale-sale___${LOCALE}` &&
          from.name === `sale-sale___${LOCALE}`) ||
        to.hash === '#professores'
      ) {
        return;
      }

      if (
        (to.name === `courses___${LOCALE}` &&
          from.name === `courses___${LOCALE}`) ||
        (to.name === `coursesTopic___${LOCALE}` &&
          from.name === `courses___${LOCALE}`)
      ) {
        const targetSelector = '.courses-filter__side';
        let offset = { x: 0, y: 16 };

        return new Promise((resolve) => {
          setTimeout(() => {
            const element = document.querySelector(targetSelector);
            if (process.client) {
              if (window?.innerWidth < 600) {
                offset = { x: 0, y: 92 };
              }
            }
            if (element) {
              resolve({
                selector: targetSelector,
                offset,
              });
            } else {
              resolve({ x: 0, y: 0 });
            }
          }, 150);
        });
      }

      return { x: 0, y: 0 };
    },
  });
}

function fixRoutes(defaultRoutes, LOCALE, country) {
  const dirctionSlugRoutes = [
    {
      name: 'blog',
      slugName: 'blogSlug',
      directions: 'direction',
    },
    {
      name: 'webinars',
      slugName: 'eventSlug',
      directions: 'directions',
    },
  ];

  const slashRoutes = [];
  dirctionSlugRoutes.forEach((dsRoute) => {
    slashRoutes.push({
      path: `/${dsRoute.name}/`,
      redirect: `/${dsRoute.name}`,
      pathToRegexpOptions: {
        strict: true,
      },
    });
  });

  const routes = defaultRoutes.map((route) => {
    dirctionSlugRoutes.forEach((dsRoute) => {
      if (route.name === `${dsRoute.name}-${dsRoute.directions}___${LOCALE}`) {
        route.beforeEnter = function (to, from, next) {
          let directions = [];
          if (process.client) {
            directions = window.__NUXT__.state.directions.list;
          } else {
            directions = proxyStore.state.store.state.directions.list;
          }

          const findedIndex = directions.findIndex(
            (i) => i[dsRoute.slugName] === to.params[dsRoute.directions],
          );
          if (findedIndex !== -1 || !to.params[dsRoute.directions]) {
            return next();
          } else {
            return next({
              name: `${dsRoute.name}-slug___${LOCALE}`,
              params: { slug: to.params[dsRoute.directions] },
              query: { ...to.query },
              replace: true,
            });
          }
        };
        route.pathToRegexpOptions = {
          strict: true,
        };
      }
      if (route.name === `${dsRoute.name}-slug___${LOCALE}`) {
        route.beforeEnter = function (to, from, next) {
          if (!to.params.slug) {
            return next({
              name: `${dsRoute.name}-${dsRoute.directions}___${LOCALE}`,
            });
          } else return next();
        };
        route.pathToRegexpOptions = {
          strict: true,
        };
      }
    });

    if (route.path === '/') {
      if (country !== 'br' && country !== 'mx') {
        route.component = OldIndex;
      }
    } else if (route.path === '/index1') {
      route.beforeEnter = function (to, from, next) {
        return next('/');
      };
    }

    return route;
  });

  const customRoutes = [];
  return [...slashRoutes, ...routes, ...customRoutes];
}
