//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cookie from 'js-cookie';
import SharedSaleBlock from '@ebac-online/sale-banner/saleBannerNew/sharedSaleBlock.vue';
import getCookieName from '@ebac-online/sale-banner/saleBannerNew/getCookieName';

export default {
  name: 'SaleBanner',
  components: {
    SharedSaleBlock,
  },
  props: {
    classes: {
      type: Object,
    },
  },
  data() {
    return {
      isDisplay: false,
      saleBlockData: null,
      cookieName: null,
    };
  },
  computed: {
    isLandingPage() {
      const isLandingPage = this.$store.state.isLandingPage;
      return isLandingPage;
    },
  },
  async created() {
    if (!this.saleBlockData) {
      await this.setSaleBlockData();
    }

    // Если есть активная плашка
    if (this.saleBlockData) {
      this.setCookieName();
      this.show();
    }
  },
  methods: {
    async setSaleBlockData() {
      let response;

      try {
        response = await this.$axios({
          method: 'GET',
          url: `${this.$app.config.current.CMS_REST_API}/public/v2/sale-banner`,
        });

        const data = response.data.data;
        if (data) {
          this.saleBlockData = data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    onCloseBanner() {
      this.$app.backToTopButtonBottomOffset = 0;
      this.isDisplay = false;
      cookie.set(this.cookieName, 'true');
    },
    show() {
      // Если нет нужной куки, то показывается баннер
      if (!cookie.get(this.cookieName)) {
        this.isDisplay = true;
        this.$app.backToTopButtonBottomOffset = process.client
          ? window.innerWidth >= 768
            ? 60
            : 90
          : false;
      }
    },
    setCookieName() {
      this.cookieName = getCookieName(this.saleBlockData.slug);
    },
    onClick() {
      if (window?.dataLayer) {
        this.$gtm?.push({
          event: 'autoEvent',
          eventCategory: 'main_click',
          eventAction: 'sale_banner',
        });
      }
    },
  },
};
