import { render, staticRenderFns } from "./index1.vue?vue&type=template&id=3e15ef96&"
import script from "./index1.vue?vue&type=script&lang=js&"
export * from "./index1.vue?vue&type=script&lang=js&"
import style0 from "./index1.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainCourses: require('/app/landing_generator/components/mainCourses.vue').default,ReviewsSection: require('/app/landing_generator/components/reviewsSection.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VApp,VBtn})
