import config from '@/config/countries';

export const state = () => ({
  coursesList: [],
  professionsList: [],
  topSaleCoursesAndProfessions: [],
  isLoadingTopSaleCoursesAndProfessions: false,

  nomenclaturesAll: [],
  coursesCanLoadMore: false,
  professionsCanLoadMore: false,
  directions: [],
  comingSoonDirections: [],
  activeDirections: [],
  coursesAndProfessions: [],

  isShowedMobileFilter: false,

  types: [],
  topics: [],

  professionsVisible: true,
  professionsBuzy: false,
  professionsTotal: 0,
  coursesVisible: true,
  coursesBuzy: false,
  coursesTotal: 0,

  mobileFilterOpened: false,
  firstTimeLoaded: false,

  coursesBranding: {},
});

export const getters = {
  GET_TYPE_ID_BY_SLUG: (state) => (slug) => {
    return state.types.find((i) => i.slug === slug)?.value;
  },
  GET_TYPE_SLUG_BY_ID: (state) => (id) => {
    return state.types.find((i) => i.value === id)?.slug;
  },

  GET_DIRECTION_SLUG_FROM_ID: (state) => (id) => {
    return state.directions.find((i) => i.id === +id)?.slug;
  },
  GET_DIRECTION_ID_FROM_SLUG: (state) => (slug) => {
    return state.directions.find((i) => i.slug === slug)?.id;
  },
  GET_DIRECTION_FROM_SLUG: (state) => (slug) => {
    return state.directions.find((i) => i.slug === slug);
  },
  GET_DIRECTION_FROM_ID: (state) => (id) => {
    return state.directions.find((i) => i.id === +id);
  },

  GET_TOPIC_FROM_SLUG: (state) => (slug) => {
    return state.topics.find((i) => i.slug === slug);
  },
  computedTopSaleCoursesAndProfessions: (state) => {
    return state.topSaleCoursesAndProfessions;
  },
  computedIsLoadingTopSaleCoursesAndProfessions(state) {
    return state.isLoadingTopSaleCoursesAndProfessions;
  },
  computedCoursesBranding(state) {
    return state.coursesBranding;
  },
};

export const mutations = {
  setFirstTimeLoaded(state, payload) {
    state.firstTimeLoaded = payload;
  },
  setNomenclaturesAll(state, nomenclatures) {
    state.nomenclaturesAll = nomenclatures;
  },
  setCourses(state, courses) {
    state.coursesList = courses;
  },
  setTopSaleCoursesAndProfessions(state, payload) {
    state.topSaleCoursesAndProfessions = payload;
  },
  setIsLoadingTopSaleCoursesAndProfessions(state, payload) {
    state.isLoadingTopSaleCoursesAndProfessions = payload;
  },
  setProfessions(state, professions) {
    state.professionsList = professions;
  },
  setProfessionsCanLoadMore(state, professionsCanLoadMore) {
    state.professionsCanLoadMore = professionsCanLoadMore;
  },
  setCoursesCanLoadMore(state, coursesCanLoadMore) {
    state.coursesCanLoadMore = coursesCanLoadMore;
  },
  setDirections(state, directions) {
    state.directions = directions;

    state.comingSoonDirections = directions.filter(
      (direction) => direction.sort === 777,
    );
    state.activeDirections = directions.filter(
      (direction) => direction.sort !== 777,
    );
  },
  setIsShowedMobileFilter(state, isShowedMobileFilter) {
    state.isShowedMobileFilter = isShowedMobileFilter;
  },
  setCoursesAndProfessions(state, payload) {
    state.coursesAndProfessions = payload.AllData.filter(
      (itemFirst) =>
        !payload.filterData.some(
          (itemSecond) => itemFirst.id === itemSecond.id,
        ),
    );
  },
  setTypes(state, payload) {
    state.types = payload;
  },

  updateDirections(state, payload) {
    state.directions = payload;
  },
  updateTopics(state, payload) {
    state.topics = payload;
  },

  setCoursesVisible(state, payload) {
    state.coursesVisible = payload;
  },
  setCoursesBuzy(state, payload) {
    state.coursesBuzy = payload;
  },
  setCoursesTotal(state, total) {
    state.coursesTotal = total;
  },
  setProfessionsVisible(state, payload) {
    state.professionsVisible = payload;
  },
  setProfessionsBuzy(state, payload) {
    state.professionsBuzy = payload;
  },
  setProfessionsTotal(state, total) {
    state.professionsTotal = total;
  },

  setMobileFilterOpened(state, payload) {
    state.mobileFilterOpened = payload;
  },
  setCoursesBranding(state, payload) {
    state.coursesBranding = payload;
  },
};

export const actions = {
  async GET_TOPICS({ state, commit }, { vm } = {}) {
    const currentConfig = config(this.$config.COUNTRY);
    const url = `${currentConfig.current.CMS_REST_API}/public/v2/topics`;

    // Если топики уже загружены - получаем из стора
    if (state.topics.length) return state.topics;

    // Если топики ещё не загружены - получаем с сервера
    try {
      const response = await this.$axios({
        method: 'get',
        url,
        params: {
          sortBySortAndName: true,
        },
      });

      commit('updateTopics', response.data.data);
      return response.data.data;
    } catch (error) {
      return false;
    }
  },
  async GET_DIRECTIONS({ state, commit }, { vm }) {
    try {
      let directions = JSON.parse(JSON.stringify(state.directions));
      directions[0].name = vm.$t('courses.filter.directions.items.todos');
      directions[0].id = '';

      directions = directions.filter(
        (i) => (i.active && !!i.coursesCount) || !i.id,
      );

      commit('updateDirections', directions);
      return directions;
    } catch (e) {
      return [];
    }
  },
  async GET_TYPES({ state, commit }, { vm, messages }) {
    if (!state.types.length) {
      // Здесь можно получить данные с сервера,
      // но на данный момент используем хардкод
      const types = [
        {
          label: getI18nString({
            vm,
            messages,
            code: 'courses.filter.types.items.todos',
          }),
          value: '',
          slug: '',
        },
        {
          label: getI18nString({
            vm,
            messages,
            code: 'courses.filter.types.items.course',
          }),
          value: '0',
          slug: 'course',
        },
        {
          label: getI18nString({
            vm,
            messages,
            code: 'courses.filter.types.items.profession',
          }),
          value: '1',
          slug: 'profession',
        },
      ];
      commit('setTypes', types);
    }

    return state.types;
  },
  async fetchTopSaleCoursesAndProfessions({ commit }, { vm }) {
    const url = '/public/v2/showcase/';
    try {
      commit('setIsLoadingTopSaleCoursesAndProfessions', true);
      const currentConfig = config(this.$config.COUNTRY);
      let response = await this.$axios.get(
        `${currentConfig.current.CMS_REST_API}${url}`,
        {
          params: {
            types: [0, 1],
            limit: 6,
            topSale: true,
          },
        },
      );
      const payloadData = response.data.data.data;
      commit('setTopSaleCoursesAndProfessions', payloadData);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setIsLoadingTopSaleCoursesAndProfessions', false);
    }
  },
  async fetchCoursesBranding({ commit, getters }, { previewId }) {
    const coursesBranding = getters.computedCoursesBranding;
    if (coursesBranding && Object.keys(coursesBranding).length > 0) {
      return coursesBranding;
    }

    if (previewId) {
      try {
        const currentConfig = config(this.$config.COUNTRY);
        const previewCoursesbrandingUrl = `${currentConfig.current.CMS_REST_API}/public/v2/courses-branding-preview/${previewId}`;
        const response = await this.$axios.get(previewCoursesbrandingUrl);
        commit('setCoursesBranding', response?.data?.data);
        return;
      } catch (error) {
        console.error(error);
        return;
      }
    }

    try {
      const currentConfig = config(this.$config.COUNTRY);
      const currentCoursesBrandingUrl = `${currentConfig.current.CMS_REST_API}/public/v2/current-courses-branding`;
      const response = await this.$axios.get(currentCoursesBrandingUrl);

      if (response.data.data) {
        commit('setCoursesBranding', response.data.data);
        return;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  },
};

function getI18nString({ vm, messages, code }) {
  if (messages) return messages[code];
  if (vm) return vm.$t(code);
  return code;
}
